import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Alignment, SlashHeader, Slide } from '@resident-advisor/design-system'
import chunk from 'lodash/chunk'
import FullWidth from '@/components/generic/full-width'
import testIds from '@/enums/testIds'

const PopularItemsVertical = ({ Card, data, title }) => {
  const groups = useMemo(() => {
    const dataWithRank = data.map((item, index) => ({
      ...item,
      rank: index + 1,
    }))
    return chunk(dataWithRank, 2)
  }, [data])

  return (
    <>
      <SlashHeader>{title}</SlashHeader>
      <FullWidth sizes={['s']}>
        <Slide mCols={1} mWrap lWrap gridRowGap={0} data-testid={testIds.slide}>
          {groups.map((group, index) => (
            <Slide.Item key={index} data-testid={testIds.slideItem}>
              <Alignment flexDirection="column">
                {group.map((item) => (
                  <Card key={item.id} {...item} />
                ))}
              </Alignment>
            </Slide.Item>
          ))}
        </Slide>
      </FullWidth>
    </>
  )
}

PopularItemsVertical.propTypes = {
  Card: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default PopularItemsVertical
