import PropTypes from 'prop-types'
import {
  Alignment,
  Box,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import unescape from 'lodash/unescape'
import ASPECT_RATIO from 'lib/aspectRatio'
import { newsByIdRoute } from 'lib/routes'
import ResponsiveImage from '@/components/generic/responsive-image'
import CmsContent from '@/components/generic/cms-content'
import Date from '@/components/generic/date'
import CardMeta from '@/components/generic/card-meta'
import Link from '@/components/generic/link'
import Heading from '@/components/generic/heading'
import useNewsTypeTitle from '@/hooks/useNewsTypeTitle'
import testIds from '@/enums/testIds'

const HorizontalNewsListingCard = ({
  blurb,
  type,
  contentUrl,
  formatTypeUrl,
  date,
  dateFormat = Date.Formats.Time,
  imageUrl,
  title,
}) => {
  const newsTypeTitle = useNewsTypeTitle(type)

  const metaItems = [{ date, dateFormat }]

  if (type) {
    metaItems.push({
      text: newsTypeTitle,
      href: formatTypeUrl(type),
    })
  }

  return (
    <Box role="listitem" data-testid={testIds.horizontalNewsListing}>
      <Grid sCols={4} mCols={8} gridRowGap={2}>
        <Column sSpan={1} mSpan={3}>
          <Link noStyle {...newsByIdRoute(contentUrl)}>
            <ResponsiveImage
              aspect={{ s: ASPECT_RATIO['1:1'], m: ASPECT_RATIO['16:9'] }}
              url={imageUrl}
              alt={`${title} image`}
              sizes={{ s: '25vw' }}
            />
          </Link>
        </Column>
        <Column sSpan={3} mSpan={5}>
          <Alignment flexDirection="column">
            <CardMeta items={metaItems} />
            <Heading>
              <Link
                {...newsByIdRoute(contentUrl)}
                variant={variants.text.heading.m}
              >
                {unescape(title)}
              </Link>
            </Heading>
            <CmsContent
              content={blurb}
              mt={2}
              display={{ s: 'none', m: 'block' }}
            />
          </Alignment>
        </Column>
      </Grid>
    </Box>
  )
}

HorizontalNewsListingCard.propTypes = {
  blurb: PropTypes.string.isRequired,
  type: PropTypes.string,
  contentUrl: PropTypes.string.isRequired,
  formatTypeUrl: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default HorizontalNewsListingCard
