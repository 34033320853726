import { useIntl } from 'react-intl'
import messages from '@/messages/magazine'
import formatNewsType from './formatNewsType'

const useNewsTypeTitle = (type) => {
  const intl = useIntl()

  return intl.formatMessage(messages[formatNewsType(type)])
}

export default useNewsTypeTitle
