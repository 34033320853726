import PropTypes from 'prop-types'
import { Alignment, Text, variants } from '@resident-advisor/design-system'
import unescape from 'lodash/unescape'
import { newsByIdRoute } from 'lib/routes'
import Link from '@/components/generic/link'
import { useThemeContext } from '@/hooks/useThemeContext'

const PopularNewsCard = ({ title, contentUrl, rank }) => {
  const theme = useThemeContext()

  return (
    <Alignment
      flexDirection="row"
      py={3}
      borderTop="1px solid"
      borderColor={theme.divider.borderColor}
    >
      <Text variant={variants.text.heading.m} color="secondary" mr={3}>
        {rank}
      </Text>
      <Link
        fontWeight="bold"
        variant={variants.text.body}
        {...newsByIdRoute(contentUrl)}
      >
        {unescape(title)}
      </Link>
    </Alignment>
  )
}

PopularNewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
}

export default PopularNewsCard
