import PropTypes from 'prop-types'
import { Box, variants } from '@resident-advisor/design-system'
import { StickyContainer } from 'react-sticky'
import { groupDataByDateInterval } from 'lib/dataGrouper'
import HorizontalNewsListingCard from '@/components/editorial/cards/horizontal-news-listing'
import LatestNewsCard from '@/components/editorial/cards/latest-news'
import GroupedHeader from '@/components/generic/grouped-header'
import Sticky from '@/components/generic/sticky'
import useDateFnsLocale from '@/hooks/useDateFnsLocale'
import testIds from '@/enums/testIds'

const GroupedNews = ({
  dateFormat,
  firstItemsLarge,
  formatTypeUrl,
  groupDataInterval,
  hideNewsType,
  newsItems,
  variant,
}) => {
  const locale = useDateFnsLocale()

  const groupedNews = groupDataByDateInterval({
    data: newsItems,
    dataKeyField: 'date',
    interval: groupDataInterval,
    locale,
  })

  return (
    <StickyContainer>
      {groupedNews.map((group, groupIndex) => (
        <Box
          key={`newsGroup-${group.key}`}
          data-testid={testIds.groupedNews}
          pt={{
            s: getPadding(groupIndex, 4),
            m: getPadding(groupIndex, 5),
          }}
        >
          <Sticky variant={variant}>
            <GroupedHeader>{group.title}</GroupedHeader>
          </Sticky>

          <Box role="list">
            {group.items.map((news, itemIndex) => {
              const Card =
                firstItemsLarge && isFirst(itemIndex)
                  ? LatestNewsCard
                  : HorizontalNewsListingCard
              return (
                <Box
                  pt={getPadding(itemIndex, 3)}
                  key={`newsGroupItem-${news.id}`}
                  data-testid={testIds.groupedNewsItem}
                >
                  <Card
                    {...news}
                    dateFormat={dateFormat}
                    type={hideNewsType ? undefined : news.type}
                    formatTypeUrl={formatTypeUrl}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      ))}
    </StickyContainer>
  )
}

const isFirst = (index) => index === 0
const getPadding = (index, padding) => (isFirst(index) ? 0 : padding)

GroupedNews.propTypes = {
  dateFormat: PropTypes.string,
  firstItemsLarge: PropTypes.bool,
  formatTypeUrl: PropTypes.func,
  groupDataInterval: PropTypes.string.isRequired,
  hideNewsType: PropTypes.bool,
  newsItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
}

GroupedNews.defaultProps = {
  variant: variants.section.primary,
}

export default GroupedNews
